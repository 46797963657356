import React, { useState } from "react";
import config from "./config";
import { IoCloseOutline, IoCheckmarkOutline } from "react-icons/io5";
import Mail from "./components/Mail";
import Default from "./components/Default";
import Help from "./components/Help";
import About from "./components/About";
import Link from "./components/Link";

function App() {
  const [text, setText] = useState("help");
  const [components, setComponents] = useState([<Default />]);

  const setTextFromCodes = (t, arr) => {
    switch (t) {
      case "help":
        arr.push(<Help />);
        break;
      case "about":
        arr.push(<About />);
        break;
      case "mail":
        arr.push(<Mail />);
        break;
      case "clear":
        arr = [<p className="text-red-600 font-bold">Cleared Console</p>];
        break;
      case "linkedin":
        arr.push(
          <Link name="LinkedIn" color="#00aced" link={config.LINKEDIN} />
        );
        window.open(config.LINKEDIN, "_blank");
        break;
      case "spotify":
        arr.push(<Link name="Spotify" color="#00aced" link={config.SPOTIFY} />);
        window.open(config.SPOTIFY, "_blank");
        break;
      case "github":
        arr.push(<Link name="GitHub" color="#0F9D58" link={config.GITHUB} />);
        window.open(config.GITHUB, "_blank");
        break;
      case "defy":
        arr.push(<Link name="Defy" color="#00aced" link={config.DEFY} />);
        window.open(config.DEFY, "_blank");
        break;
      case "medium":
        arr.push(<Link name="Medium" color="#FFEA00" link={config.MEDIUM} />);
        window.open(config.MEDIUM, "_blank");
        break;
      case "ens":
        arr.push(<p className="text-blue-500 font-bold"> hasret.eth</p>);
        break;
      default:
        break;
    }

    setComponents(arr);
    setText("");
  };

  const handleKeyDown = (event, arr = components) => {
    if (event.keyCode === 13) {
      const t = event.target.value;

      arr.push(<p className="text-white font-thin">{`> ${t}`}</p>);

      setComponents(arr);
      setTextFromCodes(t, arr);
    }
  };

  return (
    <div className="bg-zinc-800 home w-full min-h-screen flex items-center justify-center p-6">
      <div
        className="rounded w-full xl:w-1/2 md:w-3/4 flex flex-col"
        style={{ height: 500 }}
      >
        <div className="rounded-t bg-zinc-500 w-full flex justify-between">
          <h1 className="text-xs p-1 font-bold text-white">
            Hasret Özkan - Co-Founder & Blockchain Developer
          </h1>
          <div className="flex items-center px-2">
            <div
              style={{ padding: 1 }}
              className="bg-blue-500 hover:bg-blue-700 duration-300 rounded-full mr-1"
            >
              <IoCheckmarkOutline className="text-xs text-white" />
            </div>
            <div
              style={{ padding: 1 }}
              className="bg-red-500 hover:bg-red-700 duration-300 rounded-full"
            >
              <IoCloseOutline className="text-xs text-white" />
            </div>
          </div>
        </div>
        <div className="h-full bg-zinc-700 rounded-b px-2 flex flex-col justify-between py-2 overflow-y-scroll">
          <div>
            {components.map((item) => (
              <div className={`text-xs my-1 text-white`}>{item}</div>
            ))}
          </div>
          <input
            type="text"
            className="w-full bg-zinc-800 rounded px-2 py-1 mt-3 text-white text-xs outline-none"
            placeholder="Type your command..."
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            value={text}
            autoFocus
          />
        </div>
      </div>
    </div>
  );
}

export default App;
