import React from "react";

export default function Default() {
  return (
    <h2 className="font-bold text-yellow-400">
      Welcome to hasret.dev <br />
      <br />
      ----- <br />
      <br />
      If you need help just type 'help' <br />
      <br />
    </h2>
  );
}
