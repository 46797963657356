import React from "react";
import config from "../config";

export default function Mail() {
  return (
    <a href={`mailto:${config.MAIL_ADDRESS}`}>
      <p className="text-blue-300 font-bold">{config.MAIL_ADDRESS}</p>
    </a>
  );
}
