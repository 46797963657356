import React from "react";

export default function About() {
  return (
    <p className="text-yellow-400 font-thin">
      I am currently working at Defy as Co-Founder and Blockchain developer.
      Together with my team, we are preparing Defy, which has many products,
      using blockchain and other technologies. I enjoy working on Web3, Web and
      Mobile technologies. I enjoy developing new products related to
      blockchain. I'm using React.js and Angular.js on the web side. I'm using
      React Native and Flutter on the mobile side. I am working with Node.js on
      the backend side. I also enjoy following new developments in technology
      and developing new technologies.
    </p>
  );
}
