import React from "react";

export default function Help() {
  return (
    <p className="font-light text-yellow-100">
      clear - Clear console <br />
      about - About me <br />
      mail - My mail address <br />
      linkedin - My Linkedin Account <br />
      github - My Github Account <br />
      spotify - My Spotify Account <br />
      defy - Defy <br />
      ens - My Ethereum Address <br />
      medium - My Medium Account
    </p>
  );
}
