import React from "react";

export default function Link({ name, color = "white", link }) {
  return (
    <a href={link}>
      <p
        className={`font-bold`}
        style={{ color }}
      >{`---> Opening ${name} page.`}</p>
    </a>
  );
}
