const config = {
  LINKEDIN: "https://www.linkedin.com/in/hasretozkan/",
  GITHUB: "https://github.com/hasretozkan",
  SPOTIFY: "https://open.spotify.com/user/hotfiremuzik",
  MAIL_ADDRESS: "hasretozkan1@gmail.com",
  DEFY: "https://getdefy.co",
  ENS: "hasret.eth",
  MEDIUM: "https://medium.com/@hasretozkan",
};

export default config;
